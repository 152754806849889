import React from 'react';
import {Box, Container, Grid} from '@material-ui/core';
import BWLogo from '../../assets/icon/Partners/BusinessWoman/bw.svg';
import WSGLogo from '../../assets/icon/Partners/WSG/wsg.svg'
import NRLogo from '../../assets/icon/Partners/NR/nrf.svg';
import BEA from '../../assets/icon/Partners/BEA/bea.png';
import NZP from '../../assets/icon/Partners/NZP/nzp.png';
import GrainPower from '../../assets/icon/Partners/GrainPower/GrainPower.svg';
import StandartCzarne from '../../assets/icon/Partners/StandartCzarne/standart-czarne.svg';

import SectionWithLine from "../UI/Title";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#fcfbff',
        padding: '100px 40px 40px 40px',
        [theme.breakpoints.down('sm')]: {
            padding: '100px 20px 0 20px'
        },
        [theme.breakpoints.up('lg')]: {
            padding: '100px 100px 0 100px'
        },
        [theme.breakpoints.up('xl')]: {
            padding: '100px 150px 0 150px'
        }
    },
    partnerLogo: {
        width: '256px',
        objectFit: 'cover',
        filter: 'grayscale(1)',
        transition: '0.5s',
        '&:hover': {
            filter: 'none',
        },
        [theme.breakpoints.down('sm')]: {
            width: '120px',
        },
    },
    logoContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
    },
}));

function Partners() {
    const classes = useStyles();
    const partners = [
        {
            id: 1,
            image: BWLogo,
            name: 'Business Woman',
        },
        {
            id: 2,
            image: WSGLogo,
            name: 'WSG',
        },
        {
            id: 3,
            image: NRLogo,
            name: 'NR',
        },
        {
            id: 4,
            image: BEA,
            name: 'BEA',
        },
        {
            id: 5,
            image: GrainPower,
            name: 'GP',
        },
        {

            id: 6,
            image: NZP,
            name: 'NZP',
        },
        {
            id: 7,
            image: StandartCzarne,
            name: 'StandartCzarne',
        },
    ];

    return (
        <Box className={classes.root} id="partners">
            <SectionWithLine title="Zaufali Nam" lineColor="#003399" textSize="fit-content" textColor="#232323"/>
            <Container maxWidth="md">
                <Grid container justifyContent="center" style={{paddingBottom: '100px', alignItems: 'center'}}>
                    {partners.map((partner) => (
                        <Box sx={{display: 'flex', justifyContent: 'center'}} key={partner.id}>
                            <Grid item key={partner.id}>
                                <Box className={classes.logoContainer}>
                                    <img src={partner.image} alt={partner.name} className={classes.partnerLogo}/>
                                </Box>
                            </Grid>
                        </Box>
                    ))}
                </Grid>
            </Container>
        </Box>
    );
}

export default Partners;