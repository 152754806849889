import React from 'react';
import './styles.css';
import 'swiper/css';
import {Box} from '@material-ui/core';
import SectionWithLine from '../UI/Title';
import Slider from '../UI/Slider';
import {makeStyles} from '@material-ui/core/styles';


const designs = [
    {
        id: 0,
        title: 'Turystyka Bez Filtrów',
        desc: '',
        imgSrc: 'https://i.ibb.co/yP04zhz/Turystyka-BEZ-FILTR-W-Redesign.png',
        imgSrcForModal: ['https://i.ibb.co/KNP2sMX/screencapture-turystykabezfiltrow-2024-03-27-22-13-16.png'],
        backgroundColor: '#9AB6FFCC',
    },
    {
        id: 1,
        title: 'Fundacja "Nad Rzeką"',
        desc: '',
        imgSrc: 'https://i.ibb.co/c31tD65/Fundacja-Redesign.png',
        imgSrcForModal: ['https://i.ibb.co/gy6M4YP/screencapture-fundacjanadrzeka-2024-03-27-22-13-42.png'],
        backgroundColor: '#78BFCECC',
    },
    {
        id: 2,
        title: 'Woda dla Kujaw',
        desc: '',
        imgSrc: 'https://i.ibb.co/7vh2ff9/Group-34.png',
        imgSrcForModal: ['https://i.ibb.co/KKDtZTB/screencapture-wisladlakujaw-pl-2024-03-27-22-12-40.png'],
        backgroundColor: '#3CAC5FCC',
    },
    {
        id: 3,
        title: 'WoodPlus',
        desc: '',
        imgSrc: 'https://i.ibb.co/stPHgQJ/Group-33.png',
        imgSrcForModal: ['https://i.ibb.co/KwwJpGC/screencapture-woodplus-ua-2024-03-27-19-54-18.png'],
        backgroundColor: '#97DD7FCC',
    },
    {
        id: 4,
        title: 'Bydgoska Szkoła Medyczna',
        desc: '',
        imgSrc: 'https://i.ibb.co/y4WJR4p/Group-Bydgoska-Szko-a-Medyczna-Redesign.png',
        imgSrcForModal: ['https://i.ibb.co/SnWgMdv/1920-1.png'],
        backgroundColor: '#DAC590',
    },
    {
        id: 5,
        title: 'Roamwell',
        desc: '',
        imgSrc: 'https://i.ibb.co/2dNpy0J/Roamwell.png',
        imgSrcForModal: ['https://i.imgur.com/PYsOENp.png', 'https://i.imgur.com/LV7y2BD.png', 'https://i.imgur.com/jzf8tTp.png', 'https://i.imgur.com/stTzdB4.png', 'https://i.imgur.com/LlsQ4S1.png', 'https://i.imgur.com/jjE8Wqp.png', 'https://i.imgur.com/ERj1VnF.png', 'https://i.imgur.com/IbKSLJK.png', 'https://i.imgur.com/N3GJk4R.png', 'https://i.imgur.com/ihU9mTl.png', 'https://i.imgur.com/9WEyrgj.png'],
        backgroundColor: "#BFAB97"
    },
    {
        id: 6,
        title: 'Stul',
        desc: '',
        imgSrc: 'https://i.ibb.co/5T856bz/Frame-Stul.png',
        imgSrcForModal: ['https://i.imgur.com/T3LmhNN.png', 'https://i.imgur.com/wwry7Yi.png', 'https://i.imgur.com/b2KlBr3.png', 'https://i.imgur.com/440kb4Y.png', 'https://i.imgur.com/oPy7xZo.jpg', 'https://i.imgur.com/bDKUZdW.png', 'https://i.imgur.com/7KjkAQc.png', 'https://i.imgur.com/i1wQloO.jpg'],
        backgroundColor: "#96838A"
    },
    {
        id: 7,
        title: 'Pets Here',
        desc: '',
        imgSrc: 'https://i.ibb.co/RSyJ4Jx/Group-Pets-Here.png',
        imgSrcForModal: ['https://i.ibb.co/1bSTr0J/1.png'],
        backgroundColor: "#B2CBFF"
    }

];

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '100px 40px 180px 40px',
        backgroundColor: '#232323',
        [theme.breakpoints.down('sm')]: {
            padding: '100px 20px 180px 20px',
        },
        [theme.breakpoints.up('lg')]: {
            padding: '100px 100px 180px 100px',
        },
        [theme.breakpoints.up('xl')]: {
            padding: '100px 150px 180px 150px',
        },
    },
    tabsContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: '40px',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
        },
    },
    tabs: {
        backgroundColor: 'transparent',
        color: '#f2f2f2',
        '& .MuiTabs-indicator': {
            backgroundColor: '#003399',
        },
    },
    tab: {
        fontSize: '14px',
        fontFamily: 'Syncopate-Regular',
        '&:hover': {
            color: '#003399',
            transition: '0.5s',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '12px',
        },
    },
}));

const Portfolio = () => {
    const classes = useStyles();

    return (
        <Box className={classes.root} id="portfolio">
            <SectionWithLine title="Projekty" lineColor="#FcFbFF" textColor="rgb(252, 251, 255)"/>
            <Slider projects={designs} lineColor="#FcFbFF" backgroundColor={designs.backgroundColor}/>
        </Box>
    );
};

export default Portfolio;
